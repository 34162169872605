<script setup lang="ts">
import { type PropType, toRefs } from 'vue';

const props = defineProps({
  variant: {
    type: String as PropType<'success' | 'error' | 'transparent'>,
    default: 'transparent',
  },
  message: {
    type: String,
    required: true,
  },
  buttonLabel: {
    type: String,
    default: '',
  },
  buttonIcon: {
    type: String,
    default: '',
  },
  opened: {
    type: Boolean,
    default: false,
  },
  animationDuration: {
    type: Number,
    default: 500,
  },
});

const { animationDuration, opened } = toRefs(props);

const variantStrategy = {
  success: {
    notification: {
      classes: 'bg-notification-success-bg c-notification-success-text text-small font-400',
    },
  },
  error: {
    notification: {
      classes: 'bg-notification-error-bg c-notification-error-text text-small font-400',
    },
  },
  transparent: {
    notification: {
      classes: 'bg-transparent c-transparent text-small font-400',
    },
  },
};
</script>

<template>
  <div
    class="w-full h-7 flex justify-center items-center gap-4 absolute top-0 left-0"
    :class="[
      variantStrategy[variant].notification.classes,
      {
        'top--7!': !opened,
      },
    ]"
    :style="{
      'transition-duration': `${animationDuration}ms`,
    }"
  >
    {{ message }}
  </div>
</template>
